<template>
	<p>Home</p>
</template>

<script>
	import { useUserStore } from './../store/user';

	export default {
		name: 'Home',
		data() {
			return {};
		},
		components: {},
		mounted() {
			// redirect to correct page
			let that = this;
			const userStore = useUserStore();
			userStore.getUser().then((response) => {
				if (response.role == 'admin') {
					that.$router.push({ path: '/customeraccounts' });
				} else {
					that.$router.push({ path: '/account' });
				}
			});
		},
		methods: {},
	};
</script>

<style></style>
