<template>
	<q-layout view="hHh lpR fFf">
		<router-view />
	</q-layout>
</template>

<script>
	export default {
		name: 'App',
	};
</script>

<style></style>
