<template>
	<NavigationBar />

	<q-page-container>
		<q-page padding>
			<div class="row">
				<div class="col-12">
					<q-table
						flat
						bordered
						title="Invoices"
						:rows="invoices"
						:columns="invoicesTableConfiguration.columns"
						row-key="id"
						:pagination="
							invoicesTableConfiguration.initialPagination
						"
						:filter="invoicesTableConfiguration.filter"
						v-model:selected="invoicesTableConfiguration.selected"
					>
						<template v-slot:top-right>
							<q-input
								borderless
								dense
								debounce="300"
								v-model="invoicesTableConfiguration.filter"
								placeholder="Search"
							>
								<template v-slot:append>
									<q-icon name="search" />
								</template>
							</q-input>
						</template>

						<template v-slot:body-cell-status="props">
							<q-td :props="props">
								<q-badge
									v-if="props.row.status == 'paid'"
									color="green"
								>
									Paid
								</q-badge>
								<q-badge
									v-else-if="props.row.status == 'open'"
									color="red"
								>
									Open
								</q-badge>
								<q-badge v-else color="grey"> Open </q-badge>
							</q-td>
						</template>

						<template v-slot:body-cell-actions="props">
							<q-td :props="props">
								<q-btn flat @click="viewpdf(props.row)">
									<q-icon name="visibility" />
								</q-btn>
							</q-td>
						</template>
					</q-table>
				</div>
			</div>
		</q-page>
	</q-page-container>
</template>

<script>
	import NavigationBar from '@/components/NavigationBar.vue';
	import { useUserStore } from './../store/user';
	import moment from 'moment';

	export default {
		name: 'Account',
		data() {
			return {
				invoices: [],
				selectedRow: null,
				invoicesTableConfiguration: {
					initialPagination: {
						rowsPerPage: 20,
						sortBy: 'invoicedate',
						descending: true,
					},
					columns: [
						{
							name: 'number',
							align: 'center',
							label: 'Number',
							field: 'number',
							sortable: true,
						},
						{
							name: 'invoicedate',
							label: 'Invoice Date',
							field: 'invoicedate',
							sortable: true,
							format: (val) => {
								return moment(val).format('DD.MM.YYYY');
							},
						},
						{
							name: 'amount',
							label: 'Amount',
							field: 'amount',
							sortable: true,
							format: (val) => {
								const eur = new Intl.NumberFormat('de-DE', {
									style: 'currency',
									currency: 'EUR',
								});
								return eur.format(val);
							},
						},
						{
							name: 'status',
							label: 'Status',
							field: 'status',
							sortable: true,
						},
						{
							name: 'actions',
							label: 'Actions',
							field: 'actions',
							sortable: false,
						},
					],
					filter: '',
					selected: [],
					selectedAction: null,
				},
			};
		},
		components: {
			NavigationBar,
		},
		mounted() {
			document.querySelector('html').classList.add('dark');

			let that = this;
			const userStore = useUserStore();
			userStore.getUserCustomerAccounts().then((response) => {
				for (let i = 0; i < response.length; i++) {
					for (let ii = 0; ii < response[i].Invoices.length; ii++) {
						let invoiceObj = response[i].Invoices[ii];
						that.invoices.push(invoiceObj);
					}
				}
			});
		},
		methods: {
			handleRowClick(item) {
				this.selectedRow = item;
			},
			viewpdf(invoice) {
				this.$router.push({
					name: 'PdfView',
					params: { invoiceId: invoice.id },
				});
			},
		},
	};
</script>

<style></style>
