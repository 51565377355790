import './styles/quasar.scss';
import '@quasar/extras/material-icons/material-icons.css';

// To be used on app.use(Quasar, { ... })
export default {
	config: {
		dark: true,
	},
	plugins: {},
};
