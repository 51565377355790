<template>
	<NavigationBar />

	<q-page-container>
		<q-page padding>
			<div>
				<q-btn
					dense
					flat
					icon="arrow_back"
					@click="
						this.$router.push({
							name: 'Home',
						})
					"
				/>
			</div>
			<div class="row">
				<div class="col-4"></div>
				<div class="col-4">
					<q-form @submit="updateOrganistion" class="q-gutter-md">
						<q-input
							filled
							v-model="organisation.name"
							label="Name"
							lazy-rules
							:rules="[
								(val) =>
									(val && val.length > 0) ||
									'Please type something',
							]"
						/>

						<q-input
							filled
							v-model="organisation.mailtext"
							type="textarea"
							label="Mailtext"
							lazy-rules
							:rules="[
								(val) =>
									(val !== null && val !== '') ||
									'Please type your Mailtext',
							]"
						/>

						<q-input
							filled
							v-model="organisation.mailfooter"
							type="textarea"
							label="Mailfooter"
							lazy-rules
							:rules="[
								(val) =>
									(val !== null && val !== '') ||
									'Please type your Mailfooter',
							]"
						/>

						<q-input
							filled
							v-model="organisation.mailbcc"
							label="Mail BCC"
							lazy-rules
							:rules="[
								(val) =>
									(val !== null && val !== '') ||
									'Please type your mailbcc',
							]"
						/>

						<div>
							<q-btn
								label="Submit"
								type="submit"
								color="primary"
							/>
						</div>
					</q-form>
				</div>
			</div>
		</q-page>
	</q-page-container>
</template>

<script>
	import NavigationBar from '@/components/NavigationBar.vue';
	import { useOrganisationStore } from './../../store/organisation';

	export default {
		name: 'OrganisationsSettings',
		components: {
			NavigationBar: NavigationBar,
		},
		data() {
			return {
				organisation: {
					id: '',
					name: '',
					mailtext: '',
					mailfooter: '',
					mailbcc: '',
				},
			};
		},
		mounted() {
			const organisationStore = useOrganisationStore();
			organisationStore
				.getCurrentOrganisation()
				.then((currentOrganisationId) => {
					organisationStore.getOrganisations().then((response) => {
						for (let i = 0; i < response.length; i++) {
							if (response[i].id == currentOrganisationId) {
								this.organisation = response[i];
							}
						}
					});
				});
		},
		methods: {
			updateOrganistion() {
				const organisationStore = useOrganisationStore();
				organisationStore
					.updateOrganisation(this.organisation)
					.then(() => {
						this.$router.push({ path: '/customeraccounts' });
					});
			},
		},
	};
</script>

<style></style>
