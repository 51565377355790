<template>
	<q-page-container>
		<q-page>
			<div class="row">
				<div class="col-md-6">
					<div class="column justify-center items-center full-height">
						<div class="row">
							<h5 class="text-h5 text-white q-my-md">
								WireCore GmbH
							</h5>
						</div>
						<div class="row">
							<q-card square bordered class="q-pa-lg shadow-1">
								<q-card-section>
									<q-form class="q-gutter-md">
										<q-input
											square
											filled
											clearable
											v-model="username"
											type="email"
											label="E-Mail / Username"
										/>
										<q-input
											square
											filled
											clearable
											v-model="password"
											type="password"
											label="Password"
										/>
									</q-form>
								</q-card-section>
								<q-card-actions class="q-px-md">
									<q-btn
										unelevated
										color="primary"
										size="lg"
										class="full-width"
										label="Login"
										@click="login"
									/>
								</q-card-actions>
								<q-card-section class="text-center q-pa-none">
									<p class="text-grey-6">
										Not reigistered? Created an Account
									</p>
								</q-card-section>
							</q-card>
						</div>
					</div>
				</div>
				<div class="col-md-6">
					<img
						class="object-cover w-full h-screen md:block"
						:src="getBackgroundImageUrl"
					/>
				</div>
			</div>
		</q-page>
	</q-page-container>
</template>

<script>
	import { useToast } from 'vue-toastification';
	import { useAuthStore } from '../store/auth';

	export default {
		name: 'Login',
		data() {
			return {
				buttonLoading: false,
				username: '',
				password: '',
				wrongPassword: false,
			};
		},
		computed: {
			getBackgroundImageUrl() {
				const randomNumber = Math.round(Math.random() * (2 - 1) + 1);
				return require(
					'@/assets/background_images/' + randomNumber + '.webp'
				);
			},
		},
		methods: {
			login() {
				let username = this.username;
				let password = this.password;

				const cac_backend_url = process.env.VUE_APP_CAC_BACKEND_URL;
				const url = cac_backend_url + 'auth/login';

				this.buttonLoading = true;

				this.axios
					.post(
						url,
						{
							username: username,
							password: password,
						},
						{ withCredentials: true }
					)
					.then((response) => {
						if (response.status == 200) {
							this.buttonLoading = false;
							let jwt = response.data.token.jwt;
							const authStore = useAuthStore();
							authStore.setJwtToken(jwt);
							if (response.data.user.role == 'user') {
								// user
								this.$router.push({ path: '/account' });
							} else {
								// admin
								this.$router.push({
									path: '/customeraccounts',
								});
							}
						}
					})
					.catch((error) => {
						console.log(error);
						this.wrongPassword = true;
						const toast = useToast();
						toast.error('Login failed');
						this.buttonLoading = false;
					});
			},
		},
	};
</script>

<style></style>
