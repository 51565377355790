import { defineStore } from 'pinia';
import { useAuthStore } from './auth';
import axios from 'axios';

const cac_backend_url = process.env.VUE_APP_CAC_BACKEND_URL;

export const useOrganisationStore = defineStore('organisation', {
	state: () => ({
		organisations: null,
		currentOrganisation: null,
	}),
	actions: {
		async getUser() {
			let user = this.user;

			if (user == null) {
				const auth = useAuthStore();
				const jwt = auth.jwt;
				const url = cac_backend_url + 'users/getCurrentUser';
				let that = this;
				await axios
					.get(url, {
						headers: {
							Authorization: jwt,
						},
					})
					.then((response) => {
						that.user = response.data;
					});
			} else {
				user = this.user;
			}
			return user;
		},
		async getCurrentOrganisation() {
			if (this.currentOrganisation == null) {
				const auth = useAuthStore();
				const jwt = auth.jwt;
				const url = cac_backend_url + 'organisations';
				let that = this;
				await axios
					.get(url, {
						headers: {
							Authorization: jwt,
						},
					})
					.then((response) => {
						that.currentOrganisation = response.data[0].id;
					});
			}

			return this.currentOrganisation;
		},
		async getOrganisations() {
			let organisations = [];

			const url = cac_backend_url + 'organisations';
			const auth = useAuthStore();
			const jwt = auth.jwt;
			await axios
				.get(url, {
					headers: {
						Authorization: jwt,
					},
				})
				.then((response) => {
					organisations = response.data;
				});

			return organisations;
		},
		async updateOrganisation(data) {
			let organisation = null;
			const auth = useAuthStore();
			const jwt = auth.jwt;

			const url = cac_backend_url + 'organisations/' + data.id;
			await axios
				.put(url, data, {
					headers: {
						Authorization: jwt,
						'Content-Type': 'application/json',
					},
				})
				.then((response) => {
					organisation = response.data;
				});

			return organisation;
		},
		async getInvoice({ invoiceId, organisationId, customeraccountId }) {
			let invoice = null;

			const url =
				cac_backend_url +
				'organisations/' +
				organisationId +
				'/customeraccounts/' +
				customeraccountId +
				'/invoices/' +
				invoiceId;
			const auth = useAuthStore();
			const jwt = auth.jwt;
			await axios
				.get(url, {
					headers: {
						Authorization: jwt,
					},
				})
				.then((response) => {
					invoice = response.data;
				});

			return invoice;
		},
		async addInvoice({ organisationId, customeraccountId, data }) {
			let invoice = null;

			const auth = useAuthStore();
			const jwt = auth.jwt;
			const url =
				cac_backend_url +
				'organisations/' +
				organisationId +
				'/customeraccounts/' +
				customeraccountId +
				'/invoices';
			await axios
				.post(url, data, {
					headers: {
						Authorization: jwt,
						'Content-Type': 'application/json',
					},
				})
				.then((response) => {
					invoice = response.data;
				});

			return invoice;
		},
		async editInvoice({
			organisationId,
			customeraccountId,
			invoiceId,
			data,
		}) {
			let invoice = null;

			const auth = useAuthStore();
			const jwt = auth.jwt;
			const url =
				cac_backend_url +
				'organisations/' +
				organisationId +
				'/customeraccounts/' +
				customeraccountId +
				'/invoices/' +
				invoiceId;
			await axios
				.put(url, data, {
					headers: {
						Authorization: jwt,
						'Content-Type': 'application/json',
					},
				})
				.then((response) => {
					invoice = response.data;
				});

			return invoice;
		},
		async deleteInvoice({ organisationId, customeraccountId, invoiceId }) {
			const auth = useAuthStore();
			const jwt = auth.jwt;
			const url =
				cac_backend_url +
				'organisations/' +
				organisationId +
				'/customeraccounts/' +
				customeraccountId +
				'/invoices/' +
				invoiceId;
			await axios
				.delete(url, {
					headers: {
						Authorization: jwt,
						'Content-Type': 'application/json',
					},
				})
				.then((response) => {
					console.log(response);
				});
		},
		async getInvoiceMailSendedCount({
			organisationId,
			customeraccountId,
			invoiceId,
		}) {
			let responseData = null;

			const auth = useAuthStore();
			const jwt = auth.jwt;
			const url =
				cac_backend_url +
				'organisations/' +
				organisationId +
				'/customeraccounts/' +
				customeraccountId +
				'/invoices/' +
				invoiceId +
				'/getsendedcount';
			await axios
				.get(url, {
					headers: {
						Authorization: jwt,
						'Content-Type': 'application/json',
					},
				})
				.then((response) => {
					responseData = response.data;
				});

			return responseData;
		},
		async sendInvoiceMail({
			organisationId,
			customeraccountId,
			invoiceId,
		}) {
			const auth = useAuthStore();
			const jwt = auth.jwt;
			const url =
				cac_backend_url +
				'organisations/' +
				organisationId +
				'/customeraccounts/' +
				customeraccountId +
				'/invoices/' +
				invoiceId +
				'/sendmail';
			await axios
				.post(url, null, {
					headers: {
						Authorization: jwt,
						'Content-Type': 'application/json',
					},
				})
				.then((response) => {
					console.log(response);
				});
		},
		async sendInvoiceMultiMail({
			organisationId,
			customeraccountId,
			invoices,
		}) {
			const auth = useAuthStore();
			const jwt = auth.jwt;
			const url =
				cac_backend_url +
				'organisations/' +
				organisationId +
				'/customeraccounts/' +
				customeraccountId +
				'/invoices/sendmultimail';
			await axios
				.post(url, invoices, {
					headers: {
						Authorization: jwt,
						'Content-Type': 'application/json',
					},
				})
				.then((response) => {
					console.log(response);
				});
		},
		async getCustomerAccounts(organisationId) {
			let customeraccounts = [];

			const url =
				cac_backend_url +
				'organisations/' +
				organisationId +
				'/customeraccounts?invoices=1';
			const auth = useAuthStore();
			const jwt = auth.jwt;
			await axios
				.get(url, {
					headers: {
						Authorization: jwt,
					},
				})
				.then((response) => {
					customeraccounts = response.data;
				});

			return customeraccounts;
		},
		async getCustomerAccount({ organisationId, customeraccountId }) {
			let customeraccount = null;

			const url =
				cac_backend_url +
				'organisations/' +
				organisationId +
				'/customeraccounts/' +
				customeraccountId +
				'?invoices=1';
			const auth = useAuthStore();
			const jwt = auth.jwt;
			await axios
				.get(url, {
					headers: {
						Authorization: jwt,
					},
				})
				.then((response) => {
					customeraccount = response.data;
				});

			return customeraccount;
		},
		async addCustomeraccount({ organisationId, data }) {
			const auth = useAuthStore();
			const jwt = auth.jwt;

			let customeraccount = null;

			const url =
				cac_backend_url +
				'organisations/' +
				organisationId +
				'/customeraccounts';
			await axios
				.post(url, data, {
					headers: {
						Authorization: jwt,
						'Content-Type': 'application/json',
					},
				})
				.then((response) => {
					customeraccount = response.data;
				});

			return customeraccount;
		},
		async updateCustomeraccount({
			organisationId,
			customeraccountId,
			data,
		}) {
			const auth = useAuthStore();
			const jwt = auth.jwt;

			let customeraccount = null;

			const url =
				cac_backend_url +
				'organisations/' +
				organisationId +
				'/customeraccounts/' +
				customeraccountId;
			await axios
				.put(url, data, {
					headers: {
						Authorization: jwt,
						'Content-Type': 'application/json',
					},
				})
				.then((response) => {
					customeraccount = response.data;
				});

			return customeraccount;
		},
	},
});
