import { defineStore } from 'pinia';
import { useAuthStore } from './auth';
import axios from 'axios';

const cac_backend_url = process.env.VUE_APP_CAC_BACKEND_URL;

export const useUserStore = defineStore('user', {
	state: () => ({
		user: null,
		customeraccounts: [],
	}),
	actions: {
		async getUser() {
			let user = this.user;

			if (user == null) {
				const auth = useAuthStore();
				const jwt = auth.jwt;
				const url = cac_backend_url + 'users/getCurrentUser';
				let that = this;
				await axios
					.get(url, {
						headers: {
							Authorization: jwt,
						},
					})
					.then((response) => {
						that.user = response.data;
					})
					.catch(function (error) {
						console.log(error);
					});
			}
			return this.user;
		},
		async updateUser(data) {
			this.user.username = data.username;
			this.user.firstname = data.firstname;
			this.user.lastname = data.lastname;
			this.user.city = data.city;
			this.user.country = data.country;
			this.user.language = data.language;

			let user = this.user;
			const auth = useAuthStore();
			const jwt = auth.jwt;

			const url = cac_backend_url + 'users/edit';
			let that = this;
			await axios
				.put(url, JSON.stringify(user), {
					headers: {
						Authorization: jwt.jwt,
						'Content-Type': 'application/json',
					},
				})
				.then((response) => {
					that.user = response.data;
				});

			return this.user;
		},
		async getUserCustomerAccounts() {
			let customeraccounts = [];

			const url = cac_backend_url + 'users/customeraccounts?invoices=1';
			const auth = useAuthStore();
			const jwt = auth.jwt;
			await axios
				.get(url, {
					headers: {
						Authorization: jwt,
					},
				})
				.then((response) => {
					customeraccounts = response.data;
				});

			return customeraccounts;
		},
		async getUserInvoice(invoiceId) {
			let invoice = null;

			const url = cac_backend_url + 'users/invoice/' + invoiceId;
			const auth = useAuthStore();
			const jwt = auth.jwt;
			await axios
				.get(url, {
					headers: {
						Authorization: jwt,
					},
				})
				.then((response) => {
					invoice = response.data;
				});

			return invoice;
		},
	},
});
