<template>
	<NavigationBar />

	<q-page-container>
		<q-page padding>
			<div>
				<q-btn
					dense
					flat
					icon="arrow_back"
					@click="
						this.$router.push({
							name: 'CustomeraccountsView',
							params: {
								customeraccountId:
									this.$route.params.customeraccountId,
							},
						})
					"
				/>
			</div>
			<div class="row">
				<div class="col-md-2"></div>
				<div class="col-md-8">
					<q-card class="q-pa-md" flat bordered>
						<q-card-section horizontal>
							<template v-if="showedPdf == null">
								<p>Loading ...</p>
							</template>
							<template v-else>
								<div v-if="pageCount > 1">
									<button @click="currentPage--">
										Vorherige Seite
									</button>
									<button @click="currentPage++">
										Nächste Seite
									</button>
								</div>
								<div>
									{{ currentPage }} / {{ pageCount }}
									<pdf
										:src="showedPdf"
										:page="currentPage"
										@num-pages="pageCount = $event"
										@page-loaded="currentPage = $event"
									/>
								</div>
							</template>

							<q-card-actions vertical class="q-px-md full-width">
								<q-list dense>
									<q-item>
										<q-item-section>
											Status
										</q-item-section>
										<q-item-section side>
											<q-item-label>
												<q-badge
													v-if="
														this.invoice.status ==
														'Offen'
													"
													color="red"
												>
													{{ this.invoice.status }}
												</q-badge>
												<q-badge v-else color="green">
													{{ this.invoice.status }}
												</q-badge>
											</q-item-label>
										</q-item-section>
									</q-item>
									<q-item>
										<q-item-section>
											Number
										</q-item-section>
										<q-item-section side>
											<q-item-label>
												{{ this.invoice.number }}
											</q-item-label>
										</q-item-section>
									</q-item>
									<q-item>
										<q-item-section>
											Amount
										</q-item-section>
										<q-item-section side>
											<q-item-label>
												{{ this.invoice.amount }}
											</q-item-label>
										</q-item-section>
									</q-item>
									<q-item>
										<q-item-section>
											Invoicedate
										</q-item-section>
										<q-item-section side>
											<q-item-label>
												{{ this.invoice.invoicedate }}
											</q-item-label>
										</q-item-section>
									</q-item>
									<q-item>
										<q-item-section>
											Billingdate
										</q-item-section>
										<q-item-section side>
											<q-item-label>
												<span
													v-if="
														this.invoice.status !=
														'Offen'
													"
													style="float: right"
													>{{
														this.formatInvoiceDate(
															this.invoice
																.billingdate
														)
													}}</span
												><span
													v-else
													style="float: right"
													>---</span
												>
											</q-item-label>
										</q-item-section>
									</q-item>
								</q-list>

								<q-btn color="primary" @click="download"
									>Download</q-btn
								>
							</q-card-actions>
						</q-card-section>
					</q-card>
				</div>
			</div>
		</q-page>
	</q-page-container>
</template>

<script>
	import pdfvuer from 'pdfvuer';
	import NavigationBar from '@/components/admin/NavigationBar.vue';
	import moment from 'moment';
	import { useOrganisationStore } from './../../store/organisation';

	export default {
		name: 'AdminPdfViewer',
		data() {
			return {
				invoice: {
					number: '',
					amount: '',
				},
				currentPage: 0,
				pageCount: 0,
				showedPdf: null,
			};
		},
		components: {
			NavigationBar,
			pdf: pdfvuer,
		},
		mounted() {
			document.querySelector('html').classList.add('dark');

			const invoiceId = this.$route.params.invoiceId;
			const customeraccountId = this.$route.params.customeraccountId;

			let that = this;

			const organisationStore = useOrganisationStore();
			organisationStore.getCurrentOrganisation().then((response) => {
				organisationStore
					.getInvoice({
						organisationId: response,
						customeraccountId: customeraccountId,
						invoiceId: invoiceId,
					})
					.then((response2) => {
						that.invoice = response2;

						if (that.invoice.status == 'open') {
							that.invoice.status = 'Offen';
						} else {
							that.invoice.status = 'Bezahlt';
						}
						that.invoice.amount = new Intl.NumberFormat('de-DE', {
							style: 'currency',
							currency: 'EUR',
						}).format(that.invoice.amount);
						that.invoice.invoicedate = new Intl.DateTimeFormat(
							'de-DE'
						).format(new Date(that.invoice.invoicedate));

						that.showedPdf =
							'data:application/pdf;base64,' + that.invoice.file;
						that.currentPage = 1;
					});
			});
		},
		methods: {
			formatInvoiceDate(date) {
				return moment(date).format('DD.MM.YYYY');
			},
			download() {
				const downloadLink = document.createElement('a');
				downloadLink.href = this.showedPdf;
				downloadLink.download = 'invoice.pdf';
				downloadLink.click();
			},
		},
	};
</script>

<style></style>
