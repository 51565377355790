<template>
	<NavigationBar />

	<q-page-container>
		<q-page padding>
			<div class="row">
				<div class="col-12">
					<BarChart :chartData="this.chartData" />
				</div>
				<div class="col-12">
					<q-table
						flat
						bordered
						grid
						title="Customeraccounts"
						:rows="customeraccounts"
						:columns="columns"
						row-key="id"
						:filter="filter"
						hide-header
						:pagination="initialPagination"
					>
						<template v-slot:top>
							<q-space />
							<q-input
								borderless
								dense
								debounce="300"
								color="primary"
								v-model="filter"
								placeholder="Search"
							>
								<template v-slot:append>
									<q-icon name="search" />
								</template>
							</q-input>
						</template>

						<template v-slot:item="props">
							<div
								class="q-pa-xs col-xs-12 col-sm-6 col-md-4 col-lg-4 grid-style-transition"
							>
								<q-card bordered flat>
									<q-card-section>
										<div v-if="props.row.type == 'b2b'">
											{{ props.row.organisation }}
										</div>
										<div v-else>
											{{
												props.row.firstname +
												' ' +
												props.row.lastname
											}}
										</div>
										<q-btn
											class="absolute-right q-ma-sm"
											flat
											@click="
												viewCustomeraccount(props.row)
											"
										>
											<q-icon name="arrow_forward" />
										</q-btn>
									</q-card-section>
									<q-separator />
									<q-list dense>
										<q-item
											v-for="col in props.cols.filter(
												(col) => col.name !== 'name'
											)"
											:key="col.id"
										>
											<q-item-section>
												<q-item-label>
													{{
														col.label
													}}</q-item-label
												>
											</q-item-section>
											<q-item-section side>
												<q-item-label
													v-if="
														col.name !=
														'open_invoices'
													"
													caption
													>{{
														col.value
													}}</q-item-label
												>
												<q-item-label v-else caption>
													<q-badge
														v-if="col.value == 0"
														color="green"
													>
														{{ col.value }}
													</q-badge>
													<q-badge v-else color="red">
														{{ col.value }}
													</q-badge>
												</q-item-label>
											</q-item-section>
										</q-item>
									</q-list>
								</q-card>
							</div>
						</template>
					</q-table>
				</div>
			</div>
			<q-page-sticky position="bottom-right" :offset="[18, 18]">
				<q-btn
					fab
					icon="add"
					color="primary"
					@click="openCustomeraccountDialog()"
				/>
			</q-page-sticky>
			<q-dialog v-model="customeraccountDialogSwitch">
				<q-card style="width: 700px; max-width: 80vw">
					<q-card-section class="row items-center q-pb-none">
						<div class="text-h6">Invoice</div>
						<q-space />
						<q-btn icon="close" flat round dense v-close-popup />
					</q-card-section>

					<q-card-section>
						<q-form
							@submit="submitCustomeraccountDialog"
							class="q-gutter-md"
						>
							<q-select
								standout
								v-model="customeraccountDialog.type"
								:options="[
									{ label: 'B2B', value: 'b2b' },
									{ label: 'B2C', value: 'b2c' },
								]"
								label="Type"
								:rules="[
									(val) =>
										(val !== null && val !== '') ||
										'Please enter a type',
								]"
							/>
							<q-input
								filled
								v-model="customeraccountDialog.number"
								label="Number"
								lazy-rules
								:rules="[
									(val) =>
										(val && val.length > 0) ||
										'Please type something',
								]"
							/>
							<q-select
								standout
								v-model="customeraccountDialog.gender"
								:options="[
									{ label: 'Male', value: 'male' },
									{ label: 'Female', value: 'female' },
								]"
								label="Gender"
								:rules="[
									(val) =>
										(val !== null && val !== '') ||
										'Please enter a gender',
								]"
							/>

							<q-input
								filled
								v-model="customeraccountDialog.firstname"
								label="Firstname"
								lazy-rules
								:rules="[
									(val) =>
										(val !== null && val !== '') ||
										'Please type your firstname',
								]"
							/>

							<q-input
								filled
								v-model="customeraccountDialog.lastname"
								label="Lastname"
								lazy-rules
								:rules="[
									(val) =>
										(val !== null && val !== '') ||
										'Please type your lastname',
								]"
							/>

							<q-input
								filled
								v-model="customeraccountDialog.organisation"
								label="Organisation"
								lazy-rules
								:rules="[
									(val) =>
										(val !== null && val !== '') ||
										'Please type your organisation',
								]"
							/>

							<q-input
								v-model="customeraccountDialog.email"
								filled
								type="email"
								label="E-Mail"
								:rules="[
									(val, rules) =>
										rules.email(val) ||
										'Please enter a valid email address',
								]"
							/>

							<div>
								<q-btn
									label="Submit"
									type="submit"
									color="primary"
								/>
							</div>
						</q-form>
					</q-card-section>
				</q-card>
			</q-dialog>
		</q-page>
	</q-page-container>
</template>

<script>
	import NavigationBar from '@/components/admin/NavigationBar.vue';
	import moment from 'moment';
	import { BarChart } from 'vue-chart-3';
	import { Chart, registerables } from 'chart.js';
	import { useOrganisationStore } from './../../store/organisation';

	Chart.register(...registerables);

	export default {
		name: 'Customeraccounts',
		data() {
			return {
				columns: [
					{
						name: 'name',
						required: true,
						align: 'left',
						field: (row) => {
							if (row.type == 'b2b') {
								return row.organisation;
							} else {
								return row.firstname + ' ' + row.lastname;
							}
						},
						format: (val) => `${val}`,
						sortable: true,
					},
					{
						name: 'number',
						align: 'center',
						label: 'Number',
						field: 'number',
						sortable: true,
					},
					{
						name: 'email',
						label: 'E-Mail',
						field: 'email',
						sortable: true,
					},
					{
						name: 'open_invoices',
						label: 'Open Invoices',
						field: (row) => {
							let open_invoices = 0;
							for (let i = 0; i < row.Invoices.length; i++) {
								if (row.Invoices[i].status !== 'paid') {
									open_invoices++;
								}
							}
							return open_invoices;
						},
					},
				],
				initialPagination: {
					rowsPerPage: 20,
				},
				customeraccounts: [],
				selectedCustomerAccountId: null,
				customeraccountDialog: {
					id: null,
					organisationId: null,
					type: null,
					number: null,
					gender: null,
					firstname: null,
					lastname: null,
					organisation: null,
					email: null,
				},
				customeraccountDialogSwitch: false,
				chartData: {},
			};
		},
		components: {
			NavigationBar,
			BarChart,
		},
		mounted() {
			document.querySelector('html').classList.add('dark');

			let that = this;
			const organisationStore = useOrganisationStore();
			organisationStore.getCurrentOrganisation().then((response) => {
				organisationStore
					.getCustomerAccounts(response)
					.then((response2) => {
						that.customeraccounts = response2;
						console.log(response2);
						// calculate for chart
						let billingMonths = [];
						let invoiceMonths = [];
						for (let i = 0; i < that.customeraccounts.length; i++) {
							for (
								let ii = 0;
								ii < that.customeraccounts[i].Invoices.length;
								ii++
							) {
								const invoice =
									that.customeraccounts[i].Invoices[ii];

								// billingdate
								if (
									invoice.billingdate !== null &&
									invoice.billingdate !== ''
								) {
									const yearMonthBilling =
										moment(invoice.billingdate).year() +
										'-' +
										moment(invoice.billingdate).format(
											'MM'
										);
									if (
										billingMonths[yearMonthBilling] ===
										undefined
									) {
										billingMonths[yearMonthBilling] = {
											yearmonth: yearMonthBilling,
											amount: 0,
										};
									}
									billingMonths[yearMonthBilling].amount +=
										parseFloat(invoice.amount);
								}

								// invoicedate
								const yearMonthInvoice =
									moment(invoice.invoicedate).year() +
									'-' +
									moment(invoice.invoicedate).format('MM');
								if (
									invoiceMonths[yearMonthInvoice] ===
									undefined
								) {
									invoiceMonths[yearMonthInvoice] = {
										yearmonth: yearMonthInvoice,
										amount: 0,
									};
								}
								invoiceMonths[yearMonthInvoice].amount +=
									parseFloat(invoice.amount);
							}
						}

						// object to array and sorting
						billingMonths = Object.values(billingMonths);
						invoiceMonths = Object.values(invoiceMonths);
						billingMonths.sort(function (a, b) {
							return (
								moment(a.yearmonth + '-01').unix() -
								moment(b.yearmonth + '-01').unix()
							);
						});
						invoiceMonths.sort(function (a, b) {
							return (
								moment(a.yearmonth + '-01').unix() -
								moment(b.yearmonth + '-01').unix()
							);
						});

						// create chart data
						const chartLabels = [];
						const billingValues = [];
						const invoiceValues = [];
						for (let i = 0; i < billingMonths.length; i++) {
							chartLabels.push(billingMonths[i].yearmonth);
							billingValues.push(billingMonths[i].amount);
						}
						for (let i = 0; i < invoiceMonths.length; i++) {
							invoiceValues.push(invoiceMonths[i].amount);
						}

						this.chartData = {
							labels: chartLabels,
							datasets: [
								{
									label: 'Invoice',
									data: invoiceValues,
									borderColor: '#156133',
									backgroundColor: '#8faef2',
								},
								{
									label: 'Billing',
									data: billingValues,
									borderColor: '#156133',
									backgroundColor: '#2564eb',
								},
							],
						};
					});
			});
		},
		methods: {
			viewCustomeraccount(item) {
				this.$router.push({
					name: 'CustomeraccountsView',
					params: {
						customeraccountId: item.id,
					},
				});
			},
			formatInvoiceDate(date) {
				return moment(date).format('DD.MM.YYYY');
			},
			fileInputHandler(event) {
				//Read File
				var selectedFile = event.target.files;
				//Check File is not Empty
				if (selectedFile.length > 0) {
					// Select the very first file from list
					var fileToLoad = selectedFile[0];
					// FileReader function for read the file.
					var fileReader = new FileReader();
					var base64;
					// Onload of file read the file content
					let that = this;
					fileReader.onload = function (fileLoadedEvent) {
						base64 = fileLoadedEvent.target.result;
						// Print data in console
						that.invoiceDialog.file = base64.split(',')[1];
					};
					// Convert data to base64
					fileReader.readAsDataURL(fileToLoad);
				}
			},
			openCustomeraccountDialog() {
				this.customeraccountDialogSwitch = true;
			},
			submitCustomeraccountDialog() {
				// TODO implement editing customeraccount
				this.addCustomeraccount();
			},
			addCustomeraccount() {
				let that = this;

				const customeraccountData = {
					type: this.customeraccountDialog.type.value,
					number: this.customeraccountDialog.number,
					gender: this.customeraccountDialog.gender.value,
					firstname: this.customeraccountDialog.firstname,
					lastname: this.customeraccountDialog.lastname,
					organisation: this.customeraccountDialog.organisation,
					email: this.customeraccountDialog.email,
				};

				const organisationStore = useOrganisationStore();
				organisationStore.getCurrentOrganisation().then((response) => {
					organisationStore
						.addCustomeraccount({
							organisationId: response,
							data: customeraccountData,
						})
						.then(() => {
							organisationStore
								.getCustomerAccounts(response)
								.then((response2) => {
									that.customeraccounts = response2;
									that.customeraccountDialogSwitch = false;
								});
						});
				});
			},
			editCustomeraccount() {
				const organisationStore = useOrganisationStore();
				organisationStore
					.updateCustomeraccount({
						organisationId:
							this.customeraccountDialog.organisation_id,
						customeraccountId: this.customeraccountDialog.id,
						data: this.customeraccountDialog,
					})
					.then(() => {
						that.customeraccountDialogSwitch = false;
					});
			},
		},
	};
</script>

<style>
	.minimal-button-trash {
		color: rgb(255, 107, 107);
	}

	.minimal-button-trash:hover {
		background-color: rgba(255, 107, 107, 0.2);
	}

	.minimal-button-edit {
		color: rgb(165, 216, 255);
	}

	.minimal-button-edit:hover {
		background-color: rgba(165, 216, 255, 0.2);
	}

	.minimal-button-mail {
		color: rgb(178, 242, 187);
	}

	.minimal-button-mail:hover {
		background-color: rgba(178, 242, 187, 0.2);
	}

	.minimal-button-view {
		color: rgb(255, 255, 255);
	}

	.minimal-button-view:hover {
		background-color: rgba(255, 255, 255, 0.2);
	}
</style>
