import { defineStore } from 'pinia';
import axios from 'axios';

const cac_backend_url = process.env.VUE_APP_CAC_BACKEND_URL;

export const useAuthStore = defineStore('auth', {
	state: () => ({
		jwt: null,
		jwtRefreshTask: null,
	}),
	actions: {
		setJwtToken(token) {
			this.jwt = token;
			this.refreshTask = setTimeout(() => this.refreshJwt(), 60000);
		},
		removeJwtToken() {
			this.jwt = null;
			this.jwtRefreshTask = null;
		},
		async refreshJwt() {
			const url = cac_backend_url + 'auth/refreshToken';
			let that = this;
			await axios
				.get(url, { withCredentials: true })
				.then((response) => {
					that.jwt = response.data.token;
				})
				.catch((response) => {
					console.log(response);
				});
			this.refreshTask = setTimeout(() => this.refreshJwt(), 60000);
		},
	},
});
