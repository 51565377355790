<template>
	<q-header elevated class="bg-primary text-white">
		<q-toolbar class="bg-primary text-white">
			<q-toolbar-title></q-toolbar-title>

			<q-avatar>
				<q-icon
					v-if="this.avatarImageBase64 === null"
					name="account_circle"
				/>
				<img v-else :src="this.avatarImageBase64" />
				<q-menu>
					<q-list style="min-width: 100px">
						<q-item clickable v-close-popup>
							<q-item-section
								><router-link to="/settings"
									>Settings</router-link
								></q-item-section
							>
						</q-item>
						<q-separator />
						<q-item clickable v-close-popup>
							<q-item-section @click="logout()"
								>Logout</q-item-section
							>
						</q-item>
					</q-list>
				</q-menu>
			</q-avatar>
		</q-toolbar>
	</q-header>
</template>

<script>
	import { useUserStore } from './../store/user';
	import { useAuthStore } from './../store/auth';

	export default {
		name: 'NavigationBar',
		data() {
			return {
				avatarImageBase64: null,
				activeUserDropdown: false,
				currentUser: {
					firstname: '',
					lastname: '',
					username: '',
				},
			};
		},
		mounted() {
			const userStore = useUserStore();
			userStore.getUser().then((response) => {
				this.currentUser = response;
			});
		},
		methods: {
			toggleUserDropdown() {
				if (this.activeUserDropdown == false) {
					this.activeUserDropdown = true;
					document.getElementById('user-dropdown').style.position =
						'absolute';
					document.getElementById('user-dropdown').style.inset =
						'0px auto auto 0px';
					setTimeout(function () {
						document.getElementById(
							'user-dropdown'
						).style.transform =
							'translate3d(' +
							(document.getElementById('user-menu-button')
								.offsetLeft -
								document.getElementById('user-dropdown')
									.offsetWidth +
								35) +
							'px, 60px, 0px)';
					}, 50);
				} else {
					this.activeUserDropdown = false;
				}
			},
			logout() {
				const authStore = useAuthStore();
				authStore.removeJwtToken();
				this.$router.push({ path: '/login' });
			},
		},
	};
</script>
